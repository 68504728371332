<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">{{
        t('Title_.subTitle.purchaseInformation')
      }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          prefix="€"
          :disabled="isDisabled"
          :value="updatedCarValue"
          @change="
            updateCurrencyField(
              'updatedCarValue',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'updatedCarValue',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.updatedCarValue')"
          :maxlength="'10'"
          :rules="[rules.currency(t('Msg_.rules.onlyNumbersAndDecimals'))]"
          data-testid="fleet-purchase-info-updated-car-value-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          prefix="€"
          :disabled="isDisabled"
          :value="carRevenueValue"
          @change="
            updateCurrencyField(
              'carRevenue',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'carRevenue',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.carRevenue')"
          :maxlength="'10'"
          :rules="[
            rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            rules.currencySmallerOrEqualThan(
              100000,
              t('Msg_.rules.maxAmount', { max: '100.000,00' })
            ),
          ]"
          data-testid="fleet-purchase-info-car-revenue-input"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import rules from '@/helpers/rules';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const updateCurrencyField = (field, value) => {
  fleetVehiclesStore.vehicleDetails[field] = value;
};

// --- Computed ---
const updatedCarValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.updatedCarValue
      )
    : fleetVehiclesStore.vehicleDetails.updatedCarValue;
});

const carRevenueValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.carRevenue
      )
    : fleetVehiclesStore.vehicleDetails.carRevenue;
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});

const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});
</script>

<style scoped lang="scss">
.v-text-field ::v-deep label {
  font-size: 13px;
}
.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
