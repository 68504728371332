<template>
  <div data-testid="regular-text-input">
    <p class="custom-label mb-1">{{ label }}</p>
    <v-textarea
      outlined
      dense
      :rows="rows"
      :value="value"
      :rules="rules"
      :disabled="disabled"
      :placeholder="placeholder"
      :background-color="disabled ? 'ashRegular' : 'white'"
      @blur="emitUserInput($event.target.value)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  rows: {
    type: String,
    required: false,
    default: '3',
  },
  value: {
    type: null,
    required: true,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
