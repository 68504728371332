<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">
        {{ t('Title_.subTitle.additionalInformation') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.vehicleType')"
          :items="selectVehicleType"
          :clearable="!isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.type"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-type-dropdown"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.brand"
          :label="t('Msg_.brand')"
          maxlength="15"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-brand-input"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.model"
          :label="t('Msg_.model')"
          maxlength="15"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-model-input"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.line"
          :label="t('Msg_.line')"
          maxlength="50"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-line-input"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.doors')"
          :items="selectDoorType"
          :clearable="!isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.doorType"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-door-type-dropdown"
        />
      </v-col>

      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.fuelType')"
          :items="selectFuelType"
          v-model="fleetVehiclesStore.vehicleDetails.fuelType"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-fuel-type-dropdown"
        />
      </v-col>

      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.transmissionType')"
          :items="selectTypeOfTransmission"
          v-model="fleetVehiclesStore.vehicleDetails.transmissionType"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-transmission-type-dropdown"
        />
      </v-col>

      <v-col :cols="colsNumber" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.chassis"
          :label="t('Msg_.chassisNumber')"
          maxlength="17"
          :disabled="isDisabled"
          :rules="
            isDisabled
              ? []
              : [
                  rules.totalSize(
                    17,
                    t('Msg_.rules.totalDigits', { total: '17' })
                  ),
                  rules.numbersAndLetters(
                    t('Msg_.rules.onlyLettersAndNumbers', { total: '17' })
                  ),
                ]
          "
          data-testid="fleet-additional-info-chassis-input"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.registrationDate')"
          v-model="fleetVehiclesStore.vehicleDetails.carIdRegistrationDate"
          data-testid="fleet-additional-info-first-registration-datepicker"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col :cols="colsNumber" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.carBranding')"
          :items="selectCarBranding"
          v-model="fleetVehiclesStore.vehicleDetails.carBranding"
          :clearable="!isDisabled"
          :disabled="isDisabled"
          data-testid="fleet-additional-info-car-branding-dropdown"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import languages from '../locales/languages';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { defineProps, computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Computed ---

const selectVehicleType = computed(() => {
  let actionSelectVehicleType = tm('Action_.selectVehicleType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectVehicleType,
    fleetVehiclesStore.vehicleResources.vehicleTypes
  );
});

const selectFuelType = computed(() => {
  let actionSelectFuelType = tm('Action_.fuelType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectFuelType,
    fleetVehiclesStore.vehicleResources.fuelTypeValues
  );
});

const selectDoorType = computed(() => {
  let actionSelectDoorType = tm('Action_.selectDoorType');
  return helpers.translateAndAdaptToVuetify(
    actionSelectDoorType,
    fleetVehiclesStore.vehicleResources.doorTypeValues
  );
});

const selectTypeOfTransmission = computed(() => {
  let actionSelectTransmissionType = tm('Action_.selectTransmissionType');
  return helpers.translateAndAdaptToVuetify(
    actionSelectTransmissionType,
    fleetVehiclesStore.vehicleResources.transmissionTypeValues
  );
});

const selectCarBranding = computed(() => {
  let actionSelectCarBranding = tm('Action_.selectCarBranding');
  return helpers.translateAndAdaptToVuetify(
    actionSelectCarBranding,
    fleetVehiclesStore.vehicleResources.carBrandingValues
  );
});

const colsNumber = computed(() => {
  return props.isDisabled ? 6 : 4;
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});

const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});
</script>

<style scoped lang="scss"></style>
