var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{class:_setup.titleClass},[_vm._v(_vm._s(_setup.t('Title_.subTitle.purchaseInformation')))])],1),_c('v-row',[_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"prefix":"€","disabled":_vm.isDisabled,"value":_setup.updatedCarValue,"label":_setup.t('Msg_.updatedCarValue'),"maxlength":'10',"rules":[_setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals'))],"data-testid":"fleet-purchase-info-updated-car-value-input"},on:{"change":function($event){_setup.updateCurrencyField(
            'updatedCarValue',
            _setup.helpers.convertValueToCurrency($event)
          )},"focus":function($event){_setup.updateCurrencyField(
            'updatedCarValue',
            _setup.helpers.convertCurrencyToFloat($event)
          )}}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"prefix":"€","disabled":_vm.isDisabled,"value":_setup.carRevenueValue,"label":_setup.t('Msg_.carRevenue'),"maxlength":'10',"rules":[
          _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
          _setup.rules.currencySmallerOrEqualThan(
            100000,
            _setup.t('Msg_.rules.maxAmount', { max: '100.000,00' })
          ),
        ],"data-testid":"fleet-purchase-info-car-revenue-input"},on:{"change":function($event){_setup.updateCurrencyField(
            'carRevenue',
            _setup.helpers.convertValueToCurrency($event)
          )},"focus":function($event){_setup.updateCurrencyField(
            'carRevenue',
            _setup.helpers.convertCurrencyToFloat($event)
          )}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }