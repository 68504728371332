<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">{{
        t('Title_.subTitle.contractInformation')
      }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.contractualPartner')"
          :items="fleetVehiclesStore.contractualPartnersList"
          :disabled="isDisabled"
          :clearable="!isDisabled"
          item-text="description"
          item-value="id"
          v-model="fleetVehiclesStore.vehicleDetails.contractualPartnerId"
          data-testid="fleet-contract-info-contractual-partner-dropdown"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.contractNumber"
          :label="t('Msg_.contractNumber')"
          maxlength="15"
          :disabled="isDisabled"
          data-testid="fleet-contract-info-contract-number-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.contractDate')"
          :disabled="isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.contractDate"
          data-testid="fleet-contract-info-contract-date-datepicker"
          type="month"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.endOfLeasing')"
          :disabled="isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.endOfLeasing"
          data-testid="fleet-contract-info-end-of-leasing-datepicker"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          :label="t('Msg_.carReturned')"
          :disabled="isDisabled"
          :clearable="!isDisabled"
          :items="selectCarReturnedOptions"
          v-model="fleetVehiclesStore.vehicleDetails.carReturned"
          data-testid="fleet-contract-info-car-returned-dropdown"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.carReturnedDate')"
          v-model="fleetVehiclesStore.vehicleDetails.carReturnedDate"
          data-testid="fleet-contract-info-car-returned-date-datepicker"
          :disabled="
            !fleetVehiclesStore.vehicleDetails.carReturned || isDisabled
          "
          type="month"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          v-model="fleetVehiclesStore.vehicleDetails.kmAllowance"
          :label="t('Msg_.kmAllowance')"
          maxlength="6"
          :rules="[rules.onlyNumbers(t('Msg_.rules.onlyNumbers'))]"
          data-testid="fleet-contract-info-km-allowance-input"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          prefix="€"
          :disabled="isDisabled"
          :value="bankGuaranteeValue"
          @change="
            updateCurrencyField(
              'bankGuarantee',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'bankGuarantee',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.bankGuarantee')"
          :maxlength="'10'"
          :rules="[
            rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            rules.currencySmallerOrEqualThan(
              100000,
              t('Msg_.rules.maxAmount', { max: '100.000,00' })
            ),
          ]"
          data-testid="fleet-contract-info-bank-guarantee-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          :disabled="isDisabled"
          prefix="€"
          :value="extraPaymentValue"
          @change="
            updateCurrencyField(
              'extraPayment',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'extraPayment',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.extraPayment')"
          :maxlength="'10'"
          :rules="[
            rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
            rules.currencySmallerOrEqualThan(
              100000,
              t('Msg_.rules.maxAmount', { max: '100.000,00' })
            ),
          ]"
          data-testid="fleet-additional-info-extra-payment-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          :disabled="isDisabled"
          prefix="€"
          :value="fleetVehiclesStore.vehicleDetails.monthlyInstallment"
          @change="
            updateCurrencyField(
              'monthlyInstallment',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'monthlyInstallment',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.monthlyInstallment')"
          :maxlength="'10'"
          :rules="[rules.currency(t('Msg_.rules.onlyNumbersAndDecimals'))]"
          data-testid="fleet-additional-info-monthly-installment-input"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import helpers from '@/helpers/helpers';
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import rules from '@/helpers/rules';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const updateCurrencyField = (field, value) => {
  fleetVehiclesStore.vehicleDetails[field] = value;
};

// --- Computed ---
const selectCarReturnedOptions = computed(() => {
  return [
    { text: t('Action_.selectCarReturned.yes'), value: true },
    { text: t('Action_.selectCarReturned.no'), value: false },
  ];
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});

const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});

const extraPaymentValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.extraPayment
      )
    : fleetVehiclesStore.vehicleDetails.extraPayment;
});

const bankGuaranteeValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.bankGuarantee
      )
    : fleetVehiclesStore.vehicleDetails.bankGuarantee;
});
</script>

<style scoped lang="scss">
.v-text-field ::v-deep label {
  font-size: 13px;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
