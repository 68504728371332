import { render, staticRenderFns } from "./obd-information.vue?vue&type=template&id=d63c041c&scoped=true&"
import script from "./obd-information.vue?vue&type=script&setup=true&lang=js&"
export * from "./obd-information.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d63c041c",
  null
  
)

export default component.exports