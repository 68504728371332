var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',[_c('v-col',{class:_setup.titleClass},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.obdInformation'))+" ")])],1),_c('v-row',[_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.imei'),"disabled":_vm.isDisabled,"maxlength":"15","rules":[
          _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
          _setup.rules.totalSize(15, _setup.t('Msg_.rules.totalDigits', { total: '15' })),
        ],"data-testid":"fleet-obd-information-imei-input"},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.imei),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "imei", $$v)},expression:"fleetVehiclesStore.vehicleDetails.imei"}})],1),_c('v-col',{class:_setup.formFieldsClass,attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.serialNumber'),"disabled":_vm.isDisabled,"maxlength":"10","rules":[
          _setup.rules.onlyNumbers(`${_setup.t('Msg_.rules.onlyNumbers')}`),
          _setup.rules.totalSize(10, _setup.t('Msg_.rules.totalDigits', { total: '10' })),
        ],"data-testid":"fleet-obd-information-serial-number-input"},model:{value:(_setup.fleetVehiclesStore.vehicleDetails.serialNumber),callback:function ($$v) {_vm.$set(_setup.fleetVehiclesStore.vehicleDetails, "serialNumber", $$v)},expression:"fleetVehiclesStore.vehicleDetails.serialNumber"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }