<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">
        {{ t('Title_.subTitle.obdInformation') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          :label="t('Msg_.imei')"
          v-model="fleetVehiclesStore.vehicleDetails.imei"
          :disabled="isDisabled"
          maxlength="15"
          :rules="[
            rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
            rules.totalSize(15, t('Msg_.rules.totalDigits', { total: '15' })),
          ]"
          data-testid="fleet-obd-information-imei-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          :label="t('Msg_.serialNumber')"
          v-model="fleetVehiclesStore.vehicleDetails.serialNumber"
          :disabled="isDisabled"
          maxlength="10"
          :rules="[
            rules.onlyNumbers(`${t('Msg_.rules.onlyNumbers')}`),
            rules.totalSize(10, t('Msg_.rules.totalDigits', { total: '10' })),
          ]"
          data-testid="fleet-obd-information-serial-number-input"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';
import rules from '@/helpers/rules';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Computed ---
const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});
</script>

<style scoped lang="scss"></style>
