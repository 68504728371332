<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">
        {{ t('Title_.subTitle.insuranceInformation') }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          :disabled="isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.insuranceProvider"
          :label="t('Msg_.insuranceProvider')"
          maxlength="15"
          data-testid="fleet-insurance-provider-input"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          data-testid="fleet-insurance-renewal-datepicker"
          :disabled="isDisabled"
          :label="t('Msg_.insuranceRenewalDate')"
          v-model="fleetVehiclesStore.vehicleDetails.insuranceRenewalDate"
        />
      </v-col>

      <v-col cols="6" :class="formFieldsClass">
        <DropdownInput
          data-testid="fleet-insurance-type-dropdown"
          :label="t('Msg_.insuranceType')"
          :items="selectInsuranceType"
          :clearable="!isDisabled"
          v-model="fleetVehiclesStore.vehicleDetails.insuranceType"
          :disabled="isDisabled"
        />
      </v-col>

      <v-col cols="6" :class="formFieldsClass">
        <RegularTextInput
          data-testid="fleet-insurance-deductible-input"
          :value="insuranceDeductibleValue"
          prefix="€"
          @change="
            updateCurrencyField(
              'insuranceDeductible',
              helpers.convertValueToCurrency($event)
            )
          "
          @focus="
            updateCurrencyField(
              'insuranceDeductible',
              helpers.convertCurrencyToFloat($event)
            )
          "
          :label="t('Msg_.deductible')"
          maxlength="12"
          :disabled="isDisabled"
          :rules="
            isDisabled
              ? []
              : [rules.currency(t('Msg_.rules.onlyNumbersAndDecimals'))]
          "
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import languages from '../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { defineProps, computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const updateCurrencyField = (field, value) => {
  fleetVehiclesStore.vehicleDetails[field] = value;
};

// --- Computed ---
const selectInsuranceType = computed(() => {
  let actionSelectInsuranceType = tm('Action_.insuranceType');
  return helpers.translateAndAdaptToVuetify(
    actionSelectInsuranceType,
    fleetVehiclesStore.vehicleResources.insuranceTypeValues
  );
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});

const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});

const insuranceDeductibleValue = computed(() => {
  return props.isDisabled
    ? helpers.convertValueToCurrency(
        fleetVehiclesStore.vehicleDetails.insuranceDeductible
      )
    : fleetVehiclesStore.vehicleDetails.insuranceDeductible;
});
</script>

<style scoped lang="scss"></style>
