<template>
  <v-container>
    <v-row>
      <v-col :class="titleClass">
        {{ t('Title_.subTitle.documentation') }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.tuvAuDate')"
          v-model="fleetVehiclesStore.vehicleDetails.streetLegalDate"
          data-testid="fleet-documentation-street-legal-datepicker"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.tuvAuRenewalDate')"
          v-model="fleetVehiclesStore.vehicleDetails.streetLegalRenewalDate"
          data-testid="fleet-documentation-street-legal-renewal-datepicker"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.inspectionDate')"
          v-model="fleetVehiclesStore.vehicleDetails.inspectionDate"
          data-testid="fleet-documentation-inspection-datepicker"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="6" :class="formFieldsClass">
        <DatePicker
          :label="t('Msg_.inspectionRenewalDate')"
          v-model="fleetVehiclesStore.vehicleDetails.inspectionRenewalDate"
          data-testid="fleet-documentation-inspection-renewal-datepicker"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isDisabled">
      <v-col cols="12">
        <TextArea
          :label="t('Msg_.comments')"
          v-model="fleetVehiclesStore.vehicleDetails.comments"
          data-testid="fleet-documentation-comments-textarea"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import languages from '../locales/languages';
import TextArea from '@/components/shared/customComponents/inputs/text-area.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Computed ---
const formFieldsClass = computed(() => {
  return props.isDisabled ? 'py-0' : '';
});

const titleClass = computed(() => {
  return props.isDisabled ? 'section-title pb-6' : 'section-title';
});
</script>

<style scoped lang="scss"></style>
